@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.TruckTease {
  position: relative;
  display: block;
  border: 1px solid $mediumGrey;
  border-radius: 4px;

  @mixin above $medium {
    &:hover {
      .TruckTease_image {
        opacity: 0.75;
      }
    }
  }
}

.TruckTease_image {
  z-index: 4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 62%;
}

.TruckTease_info {
  padding: 0.5rem 1rem;
  color: $black;
  line-height: 1.5;
}

.TruckTease_name {
  font-weight: 600;
}

.TruckTease_category {
  color: $blue;
}

/* .TruckTease_event {
  font-weight: 600;
  display: flex;
  font-size: 0.75rem;
  font-weight: 100;
  justify-content: space-between;
  position: relative;
  text-transform: uppercase;

  :global(.tippy-content) {
    text-transform: none;
  }

  :global(.tippy-content) a {
    font-weight: 500;
    color: white;
    font-size: 0.75rem;

    &:hover {
      color: $otherGrey
    }
  }

  span {
    color: $otherGrey;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50%;
  }

  img {
    width: 0.75rem;
    margin-right: 0.25rem;
    margin-bottom: -3px;
  }

  .arrow {
    color: $green;
  }

  @mixin above $medium {
    font-size: 0.625rem;
  }
}
 */
