@import '../../../../stylesheets/utils/mixins.postcss';
@import '../../../../stylesheets/variables.postcss';

.Hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: content-box;
  position: relative;
  text-align: center;
  height: 460px;
  max-width: $max-width;
  margin: 0 auto;

  @mixin below $medium {
    height: 400px;
  }
}

.Hero_content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 600px;
  padding: 2rem;
  text-align: left;
  color: white;

  h1 {
    color: white;
  }

  :global(.HomeHeader) {
    max-width: 450px;
  }

  p {
    font-size: 17px;
    line-height: 2rem;
  }

  @mixin above $medium {
    padding-left: 6rem;
  }
}

.TruckHero_photos {
  @mixin above $medium {
    position: absolute;
    right: 0;
    width: 85%;
  }
}

.Hero_image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  filter: brightness(75%);
}
