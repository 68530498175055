@import 'slick-carousel/slick/slick.css';
@import '../../../stylesheets/variables.postcss';

.slick-arrow {
  background: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 5;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    display: block;
    font-size: 1.2rem;
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    color: $blue;
  }
}

.slick-prev {
  left: 5px;

  &:before {
    content: "\EA04";
    padding-right: 1px;
  }
}

.slick-next {
  right: 5px;

  &:before {
    content: "\EA05";
    padding-left: 2px;
  }
}

.slick-list {
  margin: 0 -8px;
}

.slick-slide {
  padding: 0 8px;
}

.slick-disabled {
  display: none !important;
}