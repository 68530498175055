@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.AboutPage_body {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;

  p, ul, ol {
    font-size: 1.15rem;
  }
}

.BookingCta {
  background-color: $grey;
  text-align: center;
  padding: 2rem;

  p {
    margin-left: auto;
    margin-right: auto;

    @mixin above $medium {
      width: 50%;
    }
  }
}

.Cta {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  @mixin above $medium {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.Cta__reversed {
  @mixin above $small {
    flex-direction: row-reverse;
  }
}

.Cta_copy {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: normal;
    margin-bottom: 1rem;
  }

  p:last-of-type {
    margin-bottom: 1.25rem;
  }

}

.Cta_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 66.66%;
  width: 100%;
  border-radius: 30px;

  @mixin above $medium {
    margin-bottom: 0;
    padding-bottom: calc(33.333% - 1rem);
  }
}

.Cta_copy,
.Cta_image {
  flex: 1 1 100%;

  @mixin above $medium {
    flex: 0 0 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

.Experience_image {
  img {
    display: block;
    margin: 0 auto;
  }

  @mixin above $small {
    margin-right: 1rem;
  }
}

.Experience_table {
  border-collapse: collapse;
  text-align: center;
  width: 100%;

  td {
    padding: 1rem 0;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid $mediumGrey;
  }

  button {
    margin-top: 2.5rem;
  }

  @mixin above $small {
    margin-left: 1rem;
  }

  @mixin above $large {
    tr:last-child .Button {
      margin-top: 2.5rem;
    }
  }
}

.PrivacyPage_header {
  text-align: center;
  padding-top: 5rem;
}

.CateringCustomerImg {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}
